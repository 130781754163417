import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTopOnRouteChange from "./Components/ScrollToTop";

import Boilerplate from "./Pages/Boilerplate.js";
import Layouts from "./Pages/Layouts.js";
import HeadersFooters from "./Pages/HeadersFooters.js";
import Typefaces from "./Pages/Typefaces.js";
import Page2 from "./Pages/Page2.js"; // This can be any page
import Header from "./Components/Header.js"; // You can use this either in app.js or within individual pages depending on your needs
import Footer from "./Components/Footer.js"; // Likewise with footer (though unlikely to be needed in individual pages)
import Animations from "./Pages/Animations.js";
import TestResults from "./Pages/TestResults";
import FormsUI from "./Pages/FormsUI";
import Functions from "./Pages/Functions";
import Guides from "./Pages/Guides";

function App() {
  return (
    <div>
      <ScrollToTopOnRouteChange />
      <Header />
      <div>
        <Routes>
          <Route path="/boilerplate" element={<Boilerplate />} />
          <Route path="/" element={<Layouts />} />
          <Route path="/headersfooters" element={<HeadersFooters />} />
          <Route path="/typefaces" element={<Typefaces />} />
          <Route path="/animations" element={<Animations />} />
          <Route path="/formsUI" element={<FormsUI />} />
          <Route path="/Functions" element={<Functions />} />
          <Route path="/guides" element={<Guides />} />
          <Route path="/:componentID/testresults" element={<TestResults />} />
          <Route path="/Page2" element={<Page2 />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
