import React from "react";
import styled from "styled-components/";
import * as GlobalVariables from "../Styles/GlobalVariables";

// This is how you use props in styled components
// background: ${(props) => (props.numberofblocks ? "blue" : "red")};

const CodeBlockContainerStyled = styled.div`
  display: grid;

  grid-template-columns: ${(props) =>
    props.numberofblocks > 3
      ? `repeat(3, 1fr)`
      : `repeat(${props.numberofblocks}, 1fr)`};
  grid-template-rows: ${(props) =>
    props.numberofblocks > 3 ? `repeat(2, 1fr)` : "repeat(1, 1fr)"};
  grid-column-gap: 1.5em;
  grid-row-gap: 1.5em;

  .code-block {
    > div:not(.text-container) {
      max-height: 20em;
      overflow: scroll;
      overflow-x: hidden;
    }
    margin-bottom: 2em;
    .text-container {
      h3 {
        font-weight: bold;
        font-size: 1em;
        margin-bottom: 1.7em;
        font-style: italic;
      }
    }
  }
`;

const CodeBlockContainer = (props) => {
  return (
    <CodeBlockContainerStyled
      className="vertical-padding-normal"
      numberofblocks={props.numberofblocks}
    >
      {
        props.children /* this injects the content from wherever its used as a HOC, that way you can call the button component and put your own text */
      }
    </CodeBlockContainerStyled>
  );
};

export default CodeBlockContainer;
