import React from "react";
import styled from "styled-components/";
import * as GlobalVariables from "../Styles/GlobalVariables";
const ComponentContainerStyled = styled.div`
  .image-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 4em;
    grid-row-gap: 0px;
    > img {
      width: 100%;
      border: 1px solid silver;
      height: 100%;
      object-fit: contain;
    }
  }
  ${(props) =>
    props.typeface &&
    `
    h2{display:none;}
    display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 6em;
grid-row-gap: 0px;
  .image-container {
    display:block;
    border-right: 1px solid silver;
    > img{
      width: auto;
      border:none;

    height: auto;
    object-fit: none;
    }
  }
  
  `}
`;

const ComponentContainer = (props) => {
  return (
    <ComponentContainerStyled
      className="vertical-padding-normal"
      typeface={props.typeface}
    >
      {
        props.children /* this injects the content from wherever its used as a HOC, that way you can call the button component and put your own text */
      }
    </ComponentContainerStyled>
  );
};

export default ComponentContainer;
