import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components/";
import { Link, useParams } from "react-router-dom";
import ContactForm from "../Components/ContactForm";
import * as GlobalVariables from "../Styles/GlobalVariables";
import { useMediaQuery } from "react-responsive"; // A must for detecting responsivity
import ScrollDownArrow from "../Components/ScrollDownArrow";

//Components
import Button from "../Components/Button";
import ComponentContainer from "../Components/ComponentContainer";
import CodeBlockContainer from "../Components/CodeBlockContainer";
import { CopyBlock, dracula } from "react-code-blocks";

const TestResultsWrapper = styled.div`
  background: white;

  .intro-section {
    img {
      width: 100%;
    }
  }

  .single-result-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
`;

const OpeningSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  .text-container {
    background: white;
    color: black;
    padding: 2rem;
    border-radius: 4px;
    text-align: center;
  }
  h1 {
    text-transform: uppercase;
  }
`;

const TestResults = (props) => {
  const componentID = useParams().componentID;

  const fetchComponent = async () => {
    try {
      console.log(componentID);
      //`http://localhost:1337/api/components?filters[id][$eq]=${componentID}&populate[test_result][populate][0]=TestReults`

      const response = await fetch(
        `https://seashell-app-23kzq.ondigitalocean.app/api/components?filters[id][$eq]=${componentID}&populate[test_result][populate][0]=TestReults`
      );
      const json = await response.json();
      return json.data.map((testResults) => ({
        componentName: testResults.attributes.componentName,
        results: testResults.attributes.test_result.data.attributes.TestReults,
      }));
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const [components, setComponents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchComponent();
      setComponents(data);
      console.log(data);
    };
    fetchData();
  }, []);

  const isDesktop = useMediaQuery({
    query: `${GlobalVariables.device.laptop}`,
  }); // Use as required, a must for detecting responsivity, may be present in other components
  return (
    <TestResultsWrapper componentID={props.componentID}>
      <OpeningSectionWrapper>
        <div className="outer-grid desktop-inner-grid2 vertical-padding-large  no-bottom">
          <div className="text-container">
            <h1>Component Library</h1>
          </div>
        </div>
      </OpeningSectionWrapper>
      <div className="outer-grid desktop-inner-grid">
        {components &&
          components.map((component) => (
            <div>
              <h2>Test Results for {component.componentName}:</h2>
              <div className="results-container">
                {component.results.map((result) => (
                  <div className="single-result-container">
                    <h3>{result.Device}</h3>
                    <p>{result.TestResults}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </TestResultsWrapper>
  );
};

export default TestResults;
