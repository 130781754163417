import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components/";
import { Link } from "react-router-dom";
import ContactForm from "../Components/ContactForm";
import * as GlobalVariables from "../Styles/GlobalVariables";
import { useMediaQuery } from "react-responsive"; // A must for detecting responsivity
import ScrollDownArrow from "../Components/ScrollDownArrow";

//Components
import Button from "../Components/Button";
import ComponentContainer from "../Components/ComponentContainer";
import CodeBlockContainer from "../Components/CodeBlockContainer";
import { CopyBlock, dracula } from "react-code-blocks";

const HeadersFootersWrapper = styled.div`
  background: white;

  .intro-section {
    img {
      width: 100%;
    }
  }
`;

const OpeningSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  .text-container {
    background: white;
    color: black;
    padding: 2rem;
    border-radius: 4px;
    text-align: center;
  }
  h1 {
    text-transform: uppercase;
  }
`;

const fetchComponents = async () => {
  try {
    const response = await fetch(
      "https://seashell-app-23kzq.ondigitalocean.app/api/components?filters[Type][$eq]=Header/Footer&populate=*"
    );
    const json = await response.json();
    return json.data.map((component) => ({
      id: component.id,
      componentName: component.attributes.componentName,
      previewImages: component.attributes.previewImages,
      exampleUsageCode: component.attributes.exampleUsageCode,
      codeBlocks: component.attributes.code_blocks,
    }));
  } catch (error) {
    console.error(error);
    return null;
  }
};

const HeadersFooters = () => {
  const [components, setComponents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchComponents();
      setComponents(data);
      console.log(data);
    };
    fetchData();
    console.log(components);
  }, []);

  const isDesktop = useMediaQuery({
    query: `${GlobalVariables.device.laptop}`,
  }); // Use as required, a must for detecting responsivity, may be present in other components
  return (
    <HeadersFootersWrapper>
      <OpeningSectionWrapper>
        <div className="outer-grid desktop-inner-grid2 vertical-padding-large  no-bottom">
          <div className="text-container">
            <h1>Component Library</h1>
          </div>
        </div>
      </OpeningSectionWrapper>
      <div className="outer-grid desktop-inner-grid">
        {components.map((component) => (
          <ComponentContainer>
            <h2>{component.componentName}</h2>
            <div className="image-container">
              {component.previewImages.data.map((image) => (
                <img key={image.id} src={image.attributes.url} />
              ))}
            </div>

            <CodeBlockContainer
              numberofblocks={
                component.codeBlocks.data.length +
                1 /* +1 because the "example usage" code isn't counted in codeBlocks.data.length */
              }
            >
              <div className="code-block">
                <div className="text-container">
                  <h3>Example Usage</h3>
                </div>
                <CopyBlock
                  text={component.exampleUsageCode}
                  language={"jsx"}
                  showLineNumbers={false}
                  theme={dracula}
                  wrapLines
                />
              </div>

              {component.codeBlocks.data.map((codeBlock) => (
                <div key={codeBlock.id} className="code-block">
                  <div className="text-container">
                    <h3>{codeBlock.attributes.filePath}</h3>
                  </div>
                  <CopyBlock
                    text={codeBlock.attributes.code}
                    language={"jsx"}
                    showLineNumbers={false}
                    theme={dracula}
                    wrapLines
                  />
                </div>
              ))}
            </CodeBlockContainer>
          </ComponentContainer>
        ))}
      </div>
    </HeadersFootersWrapper>
  );
};

export default HeadersFooters;
