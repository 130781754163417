import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components/";
import { Link } from "react-router-dom";
import ContactForm from "../Components/ContactForm";
import * as GlobalVariables from "../Styles/GlobalVariables";
import { useMediaQuery } from "react-responsive"; // A must for detecting responsivity
import ScrollDownArrow from "../Components/ScrollDownArrow";
import parse from "html-react-parser";

import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
//Components
import Button from "../Components/Button";
import ComponentContainer from "../Components/ComponentContainer";
import CodeBlockContainer from "../Components/CodeBlockContainer";
import { CopyBlock, dracula } from "react-code-blocks";

import expandbutton from "../Images/expand-button.svg";

const FunctionsWrapper = styled.div`
  background: white;
  p {
    line-height: 2.2rem;
    margin-bottom: 0;
  }
  code {
    background: #cdcdcd;
    padding: 0.2rem 0.4rem;
    border: 1px solid #bebebe;
    border-radius: 0.3rem;
    color: black;
    line-height: 1rem;
    /* margin-bottom: 1.5rem; */
    display: inline-block;
  }
  li {
    margin-bottom: 1rem;
    line-height: 2rem;
  }
  li code {
    margin-bottom: 0;
  }
  h2 h3 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    color: #1968b2;
  }
  .intro-section {
    img {
      width: 100%;
    }
  }
  .accordion__button {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    h2 {
      margin-bottom: 0;
      margin-right: 1rem;
    }
    .expand-button {
      width: 2rem;
      height: auto;
    }
  }
`;

const OpeningSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  .text-container {
    background: white;
    color: black;
    padding: 2rem;
    border-radius: 4px;
    text-align: center;
  }
  h1 {
    text-transform: uppercase;
  }
`;

const fetchGuides = async () => {
  // "https://seashell-app-23kzq.ondigitalocean.app/api/components?filters[Type][$eq]=Layout&populate=*"
  try {
    const response = await fetch(
      "https://seashell-app-23kzq.ondigitalocean.app/api/guides?&populate=*"
    );
    const json = await response.json();
    return json.data.map((guide) => ({
      id: guide.id,
      guideName: guide.attributes.Title,
      guide: guide.attributes.guide,
    }));
  } catch (error) {
    console.error(error);
    // return null;
  }
};

const Functions = () => {
  const [guides, setGuide] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchGuides();
      setGuide(data);
      console.log(data);
      console.log(guides);
    };
    fetchData();
    console.log(guides);
  }, []);

  const isDesktop = useMediaQuery({
    query: `${GlobalVariables.device.laptop}`,
  }); // Use as required, a must for detecting responsivity, may be present in other components
  return (
    <FunctionsWrapper>
      <OpeningSectionWrapper>
        <div className="outer-grid desktop-inner-grid2 vertical-padding-large no-bottom">
          <div className="text-container">
            <h1>Component Library</h1>
          </div>
        </div>
      </OpeningSectionWrapper>
      <div className="outer-grid desktop-inner-grid">
        {guides.map((guide) => (
          <ComponentContainer>
            <Accordion allowZeroExpanded="true">
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h2>{guide.guideName}</h2>
                    <img
                      className="expand-button"
                      src={expandbutton}
                      alt="Expand button"
                    />
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div>{parse(`${guide.guide}`)}</div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </ComponentContainer>
        ))}
      </div>
    </FunctionsWrapper>
  );
};

export default Functions;
